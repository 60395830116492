import React, { Component } from 'react';
import GridContentBlock from '../../elements/GridContentBlock/GridContentBlock'
import './css/gridThree.css'

class GridThree extends Component {
    render() {
        return (
            this.props.data.length>5? 
            <div className="w-layout-grid grid-3">
                <div id="w-node-60253ba1b0f7-485d313b" className="g-3-d-1">
                    <GridContentBlock data={this.props.data[0]}></GridContentBlock>
                </div>
                <div id="w-node-521cfe4f9c5e-485d313b" className="g-3-d-2">
                    <GridContentBlock data={this.props.data[1]}></GridContentBlock>
                </div>
                <div id="w-node-c2bf7ded342f-485d313b" className="g-3-d-3">
                    <GridContentBlock data={this.props.data[2]}></GridContentBlock>
                </div>
                <div id="w-node-9a4effefa03e-485d313b" className="g-3-d-4">
                    <GridContentBlock data={this.props.data[3]}></GridContentBlock>
                </div>
                <div id="w-node-71d08b5b9295-485d313b" className="g-3-d-5">
                    <GridContentBlock data={this.props.data[4]}></GridContentBlock>
                </div>
                <div id="w-node-109054c6a1b2-485d313b" className="g-3-d-6">
                    <GridContentBlock data={this.props.data[5]}></GridContentBlock>
                </div>
            </div>
            :""
        );
    }
}

export default GridThree;
import React from 'react';
import axios from 'axios';
import './css/subscribe.css'
import addToMailchimp from 'gatsby-plugin-mailchimp'
class Subscribe extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
    }
  }

  handleSubmit(e){
    e.preventDefault();
    console.log(this.state)
    addToMailchimp(this.state.email,{'FNAME': this.state.name})
    this.resetForm()
    // axios({
    //   method: "POST", 
    //   url:"http://localhost:3002/send", 
    //   data:  this.state
    // }).then((response)=>{
    //   if (response.data.status === 'success') {
    //     alert("Message Sent."); 
    //     this.resetForm()
    //   } else if (response.data.status === 'fail') {
    //     alert("Message failed to send.")
    //   }
    // })
  }

  resetForm(){
    this.setState({
        name: '', email: '',
      });
  }

  
  render() {
    return(
      <div className="App">       
        <div className="parent-wrapper">
            <span className="close-btn glyphicon glyphicon-remove"></span>
            <div className="subscribe-wrapper">
              <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
                  <h1 className="post-title" style={{"color":"#000","text-align":"center","marginBottom":"30px","margin-top":"0"}}>Subscribe to our Newsletter</h1>
                  <input type="text" name="name" className="subscribe-input editors-note" placeholder="Your first name" id="name" value={this.state.name} onChange={this.onNameChange.bind(this)} />
                  <input type="email" name="email" className="subscribe-input editors-note" placeholder="Your e-mail" id="email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />                
                  <button className="submit-btn post-page-button-text" type="submit">SUBMIT</button>
              </form>
            </div>
        </div>
      </div>
    );
  }

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	  this.setState({message: event.target.value})
  }
}

export default Subscribe;
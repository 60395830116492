import React, { Component } from 'react';
import "./css/archiveHeading.css"
import logo from "../../images/logo_svg.svg"
class ArchiveHeading extends Component {
    render() {
        return (
            <img src={logo} loading="lazy" alt="" className="archive-heading"/>
        );
    }
}

export default ArchiveHeading;
import React from 'react';
import './css/postPageButton.css'
const PostPageButton = (props) => {
    return (
        <div className="post-page-button" onMouseDown={props.function || ''}>
            <div className="post-page-button-text">{props.content}</div>
        </div>
    );
};

export default PostPageButton;
import React, { Component } from 'react';
import './css/gridTwo.css'
import GridContentBlock from '../../elements/GridContentBlock/GridContentBlock'
class GridTwo extends Component {
    render() {
        return (
            this.props.data.length>5? 
            <div className="w-layout-grid grid-two">
                <div id="w-node-bed46523e7c7-485d313b" className="g-2-d-1">
                    <GridContentBlock data={this.props.data[0]}></GridContentBlock>
                </div>
                <div id="w-node-cc19c76b34e0-485d313b" className="g-2-d-2">
                    <GridContentBlock data={this.props.data[1]}></GridContentBlock>
                </div>
                <div id="w-node-d431ba2c4b78-485d313b" className="g-2-d-3">
                    <GridContentBlock data={this.props.data[2]}></GridContentBlock>
                </div>
                <div id="w-node-533fc0a9d4ce-485d313b" className="g-2-d-4">
                    <GridContentBlock data={this.props.data[3]}></GridContentBlock>
                </div>
                <div id="w-node-b5f48f678551-485d313b" className="g-2-d-5">
                    <GridContentBlock data={this.props.data[4]}></GridContentBlock>
                </div>
                <div id="w-node-196de0e62557-485d313b" className="g-2-d-6">
                    <GridContentBlock data={this.props.data[5]}></GridContentBlock>
                </div>
            </div>
            :""
        );
    }
}

export default GridTwo;
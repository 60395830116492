import React from "react";
import styled from "styled-components";

import Logo from "../images/Favicon.png";


const Wrapper = styled.div`
   
@keyframes loader {
  from {
      transform: scale(1);
  }
    to{
        transform: scale(1.3);
    }

}

.image-loader{
  width:5%;
  margin:auto;
    animation-name: loader;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate-reverse;
}
@media screen and (max-width: 991px) {
  .image-loader{
    width:10%;
   }
}
@media screen and (max-width: 767px) {
  .image-loader{
    width:20%;
   }
}

@media screen and (max-width: 479px) {
 .image-loader{
  width:20%;
 }
}
    
`;

const Loader = () => {

  return(
    <Wrapper style={{'display': 'flex', 'margin': 'auto','height':'100%'}}>
      <img src={Logo} className="image-loader" alt="Logo"/>
    </Wrapper>
  )
};

export default Loader;
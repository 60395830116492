// import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Loader from "../components/loader";
import HomePage from "../views/HomePage/HomePage"
import React, { Component } from 'react';
import Logo from "../images/logo.png"

class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state={
      loading: true,
    }
  }
  updateState = (stateName, value) => {
    this.setState({[stateName] : value});
  };
  wait = async () => {
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 1500);
    
  };
  componentDidMount() {
    this.wait();
  }
  render() {
    // if(this.state.loading !== 'undefined' && this.state.loading){
    //   return (
    //     <div className={`flex`} style={{"height":"100vh"}}>
    //       <Loader/>
    //     </div>
    //     )
    // }
    // else {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Home" url="https://thesikhmag.com/" />
        <HomePage data={data}></HomePage>
      </Layout>
    );
    // }
  }
}

// export default index;
// const IndexPage = ({data}) => (
  
//   <Layout>
//     <SEO title="Home" />
//     <HomePage data={data}></HomePage>
//   </Layout>
// )

export default IndexPage
export const query = graphql`
  query{
    allWpPost {
        nodes {
          title
          content
          date
          slug
          excerpt
          categories {
            nodes {
              slug
              name
              description
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
    }
  }
`
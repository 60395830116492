import React, { Component } from 'react';
import './css/editorsDesk.css'

class EditorsDesk extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            this.props.data?
            <div className="editors-desk-wrapper">
                <div className="editors-desk-heading">From the Editor&#x27;s Desk:</div>
                <div className="editors-note" dangerouslySetInnerHTML={{ __html: this.props.data }} />
                <div className="editors-end-line-divider"></div>
            </div>
            :""
        );
    }
}

export default EditorsDesk;